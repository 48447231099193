import { getStrapiMedia } from 'helpers/strapi'

type ImageProps = {
  src?: string
  alt?: string
  title?: string
}

export function ImageFromStrapi({ src, ...rest }: ImageProps) {
  if (!src) return null

  // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
  return <Image src={getStrapiMedia(src)} {...rest} />
}

export function Image({ src, alt, title }: ImageProps) {
  if (!src) return null

  if (!title) {
    return <img src={src} alt={alt} />
  }

  return (
    <figure>
      <img src={src} alt={alt} />
      <figcaption>{title}</figcaption>
    </figure>
  )
}
